import React from 'react'
import HotelPage from '@/pages/followup/hotelPage'

const hotel: React.FC = () => (
  <HotelPage
    backUrl="/our-platform/followup"
    forwardsUrl="/our-platform/followup"
  />
)

export default hotel
